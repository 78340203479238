<template>
  <v-main align="center">
    <v-container fluid pa-1>
      <v-row
        align="center"
        justify="center"
        style="height: 100%; max-width: 100% !important; margin: 0"
      >
        <v-dialog
          transition="slide-x-transition"
          v-model="dialog"
          hide-overlay
          persistent
          fullscreen
        >
          <v-card
            :color="this.darkmode ? 'darkDrawer' : 'lightDrawer'"
            dark
            class="text-center justify-center"
          >
            <v-container>
              <v-row align="center" justify="center" class="mt-12">
                <v-card-text class="text-uppercase">
                  <v-img
                    max-width="100%"
                    width="400px"
                    :src="
                      whiteLabel
                        ? 'so-white-label.png'
                        : 'sensor-online-animated-logo.gif'
                    "
                    style="margin: 0 auto"
                  />
                  <span
                    class="dark--text display-1 headline font-weight-light mt-4"
                    >LOADING</span
                  >
                  <span class="dark--text display-1 headline"> DASHBOARD</span>
                </v-card-text>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>

      <grid-layout
        style="
          width: 100% !important;
          min-height: 100% !important;
          overflow-x: hidden;
        "
        :layout.sync="layout"
        :cols="{ lg: 24, md: 20, sm: 2, xs: 2, xxs: 1 }"
        :col-num="24"
        :col-width="1"
        :row-height="30"
        :is-draggable="false"
        :is-resizeable="false"
        :vertical-compact="true"
        :responsive="true"
      >
        <grid-item
          v-for="item in layout"
          :key="item.dashboardModuleId"
          :static="true"
          :x.sync="item.x"
          :y.sync="item.y"
          :w.sync="item.w"
          :h.sync="item.h"
          :i.sync="item.i"
          :min-w="1"
          :min-h="1"
          :min-x="0"
          :min-y="0"
          :resizeable="false"
          :draggable="false"
        >
          <Module
            :mode="mode"
            :item="item"
            :key="item.dashboardModuleId"
            :timerTick="timerTick"
            class="pb-10 pb-md-1 pa-1"
            v-on:updateMessage="setMessage"
          />
        </grid-item>
      </grid-layout>

      <SOMessage v-if="message != ''" :message="message" />
    </v-container>
  </v-main>
</template>

<script>
import Module from "@/components/common/Module";
import { mapActions, mapState } from "vuex";
import Enum from "@/_helpers/Enum";
import SOMessage from "@/components/common/SoMessage";
import { Roles } from "@/_helpers/Role";
import VueGridLayout from "vue-grid-layout";

export default {
  name: "ModuleGrid",

  data() {
    return {
      dialog: true,
      mode: Enum.DashboardMode.DEFAULT,
      updateTimer: "",
      timerTick: 1,
      message: "",
      layout: [],
    };
  },

  computed: {
    ...mapState("users", ["currentUser", "userCompany"]),
    ...mapState("dashboards", [
      "status",
      "currentDashboard",
      "publicDashboard",
    ]),
    ...mapState("modules", { modules: "modules", moduleStatus: "status" }),
    ...mapState("configuration", ["darkmode", "license"]),
  },

  methods: {
    ...mapActions("dashboards", {
      getPublicDashboard: "getPublicDashboard",
    }),

    ...mapActions("modules", {
      getModules: "getModules",
      setModules: "setModules",
    }),

    ...mapActions("users", ["setUserByPublicDashboard"]),

    ...mapActions("configuration", ["getBaseUrl", "getLicenseTerms"]),

    setMessage(val) {
      this.message = val;
    },

    nextTick() {
      if (this.timerTick < 60) {
        this.timerTick += 1;
      } else {
        this.timerTick = 1;
      }
    },

    setLayout() {
      this.layout = [];
      this.layout = [...this.modules];
      var i = 0;
      for (var j in this.layout) {
        this.layout[j].i = i.toString();
        i++;
      }
    },
  },

  async created() {
    await this.getBaseUrl();
    await this.getLicenseTerms();
    await this.setModules([]);
    let id = this.$route.params.id;
    if (id) {
      // Get the public dashboard to get the dashboardid
      await this.getPublicDashboard(id);

      if (
        this.publicDashboard.expired == undefined ||
        this.publicDashboard.expired
      ) {
        this.$router.push("/login");
      }

      // Check if the user is allready logged in
      // if that is the case route to the dashboard
      if (
        this.currentUser != undefined &&
        this.currentUser.role != undefined &&
        this.currentUser.role != Roles.PublicDashboard
      ) {
        this.$router.push(
          `/dashboard/${this.publicDashboard.dashboard.dashboardId}`
        );
        return;
      }

      // Set current user token and role and companyId
      await this.setUserByPublicDashboard(this.publicDashboard);
      if (this.publicDashboard != null) {
        await this.getModules({
          dashboardId: this.publicDashboard.dashboard.dashboardId,
        });
        this.setLayout();
        this.updateTimer = setInterval(this.nextTick, 10000);
      } else {
        this.$router.push("/");
      }
    } else {
      this.$router.push("dashboard");
    }
  },

  components: {
    Module,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    SOMessage,
  },

  updated() {
    if (this.moduleStatus && this.status) {
      let self = this;
      setTimeout(function () {
        self.dialog = false;
      }, 1500);
    }
  },

  beforeDestroy() {
    clearInterval(this.updateTimer);
  },
};
</script>

<style scoped></style>
